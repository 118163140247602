"use strict";
var _a, _b, _c, _d;
var getNotificationLabel = function (id) { return document.querySelector(id); };
var hideNotification = function (e) {
    var lbl = e.target;
    var parent = lbl.parentElement;
    if (!parent)
        return;
    parent.classList.add('hidden');
    lbl.innerText = '';
};
(_a = getNotificationLabel('#label-notification')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', hideNotification);
(_b = getNotificationLabel('#label-success-notification')) === null || _b === void 0 ? void 0 : _b.addEventListener('click', hideNotification);
(_c = getNotificationLabel('#label-backend-success-notification')) === null || _c === void 0 ? void 0 : _c.addEventListener('click', hideNotification);
(_d = getNotificationLabel('#label-backend-error-notification')) === null || _d === void 0 ? void 0 : _d.addEventListener('click', hideNotification);
